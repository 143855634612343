<template>
  <div class="answer">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="width_1200">
      <test-paper></test-paper>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import testPaper from "@/components/testPaper.vue";

export default {
  data() {
    return {};
  },
  components: {
    BreadCrumb,
    testPaper
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.answer {
  // margin-top: -30px;
  background-color: #f5f5f5;
  font-size: 14px;
}
</style>